<template>
  <div style=" margin: -15px -15px;">
    <a-spin :spinning="spinning">
    <div class="titleTop">
      {{titleTop}}
    </div>
    <div class="liveMeet-top">
<!--      <div class="liveMeet-top-input">-->
<!--        <div style="width: 100px;text-align: right">直播会议:</div>-->
<!--        <a-select-->
<!--            v-model="topValue.meeting"-->
<!--            placeholder="请选择直播会议"-->
<!--            style="width: 200px;margin-left: 10px"-->
<!--            @change="selectAddChange">-->
<!--          <a-select-option-->
<!--              v-for="(live,index) in 5"-->
<!--              :value="'直播间' + index">-->
<!--            直播间{{index}}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </div>-->
      <div class="liveMeet-top-input">
        <div style="width: 100px;text-align: right">直播间:</div>
        <a-select
            v-model="topValue.room"
            placeholder="请选择"
            style="width: 200px;margin-left: 10px"
            @change="selectAddChange">
          <a-select-option
              v-for="(live,index) in roomListVal"
              :value="live.title">
            {{live.title}}
          </a-select-option>
        </a-select>
      </div>
      <div class="liveMeet-top-input">
        <div style="width: 100px;text-align: right">选项:</div>
        <a-select
            v-model="topValue.options"
            placeholder="请选择"
            style="width: 200px;margin-left: 10px"
            @change="selectAddChange">
          <a-select-option
              v-for="(live,index) in answersVal"
              :value="live">
            {{live}}
          </a-select-option>
        </a-select>
      </div>
      <div class="liveMeet-top-input">
        <a-button
            @click="seachBtn"
            type="primary">查询</a-button>
        <a-button
            @click="resetBtn"
            style="margin-left: 10px">重置</a-button>
      </div>
      <div class="liveMeet-top-input">
        <a-button
            @click="exportBtn"
            style="margin-left: 10px">
          <a-icon type="file-excel" style="color: #1ea134;font-size: 16px" />
          批量导出
        </a-button>
      </div>
    </div>
    <div class="activity-table">
      <!--      :rowKey="record=>record.id"    -->
      <a-table
          :rowKey="record=>record.id"
          :columns="columnsLive"
          :data-source="dataLive"
          :pagination="false">
      </a-table>
      <div style="width: 100%;height: 50px;">
        <div style="float: right;margin-right: 20px;margin-top: 15px">
          <a-pagination
              @change="paginationBtn"
              :total="tableCount" />
        </div>
      </div>
    </div>
    <div style="width:100%;height:50px;position: fixed;bottom: 50px;background-color: #f0f2f5;border-bottom: 1px solid #fff">
      <div style="width: 80%;display: flex;justify-content: right;margin-top: 10px">
        <a-button @click="returnBtn">返回上一层</a-button>
      </div>
    </div>
    </a-spin>
  </div>
</template>
<script>
import {Download_answers_exal, getAnswer, getAnswerRecord} from "../../service/liveMeet_api";
import axios from "axios";

const columnsLive = [
  {
    title: '姓名',
    dataIndex: 'memberName',
    key: 'memberName',
    width:'7%'
  },
  {
    title: '医院',
    dataIndex: 'memberHospital',
    key: 'memberHospital',
    width:'15%'
  },
  {
    title: '科室',
    dataIndex: 'memberHospitalOffices',
    key: 'memberHospitalOffices',
    ellipsis: true,
    width:'10%',
    scopedSlots: { customRender: 'memberHospitalOffices' },
  },
  {
    title: '职称',
    dataIndex: 'memberJobTitle',
    key: 'memberJobTitle',
    ellipsis: true,
    width:'10%',
    scopedSlots: { customRender: 'memberJobTitle' },
  },
  // {
  //   title: '直播会议',
  //   dataIndex: 'finish',
  //   key: 'finish',
  //   ellipsis: true,
  //   scopedSlots: { customRender: 'finish' },
  // },
  {
    title: '直播间',
    dataIndex: 'liveStreamTitle',
    key: 'liveStreamTitle',
    ellipsis: true,
    scopedSlots: { customRender: 'liveStreamTitle' },
  },
  {
    title: '选项',
    dataIndex: 'answer',
    key: 'answer',
    ellipsis: true,
    width:'6%',
    scopedSlots: { customRender: 'answer' },
  },
  {
    title: '答题时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    ellipsis: true,
    scopedSlots: { customRender: 'createdTime' },
  },
];
const dataLive = [
];
export default {
  data(){
    return{
      spinning:false,
      titleTop:'',
      roomListVal:[],
      answersVal:[],
      columnsLive,
      dataLive,
      tableCount:null,
      topValue:{
        meeting:undefined,
        room:undefined,
        options:undefined,
      }
    }
  },
  created() {
    this.getTableList()
    this.getListTitle()
  },
  methods:{
    //标题
    async getListTitle() {
      const response = await getAnswer()
      if (response.code === 0) {
        let form = response.data.rows
        for (let i=0;i<form.length;i++){
          if(form[i].id === (this.$route.query.id)*1){
            this.titleTop = form[i].title + '--' + form[i].answers
            this.roomListVal = form[i].liveMeet
            this.answersVal = form[i].answers
          }
        }
      } else {
        this.$message.warning("出错，请联系管理员", response.message)
      }
    },
    async getTableList(answer,liveStreamTitle) {
      this.spinning = true
      let id = this.$route.query.id
      let data = {
        questionId:id,
        answer:answer,
        liveStreamTitle:liveStreamTitle
      }
      const response = await getAnswerRecord(data)
      if(response.code === 0){
        this.dataLive = response.data.rows
        this.tableCount = response.data.count
      }else {
        this.$message.warning("出错，请联系管理员",response.message)
      }
      this.spinning = false
    },
    resetBtn(){
      let form = this.topValue
      //清空
      form.meeting = undefined
      form.room = undefined
      form.options = undefined
    },
    seachBtn(){
      this.getTableList(this.topValue.options,this.topValue.room)
    },
    selectAddChange(val){
      // console.log(val)
    },
    async exportBtn() {
      this.spinning = true
      let data = {
        questionId: this.$route.query.id,
        answer: this.topValue.options,
        liveStreamTitle: this.topValue.room,
        ExelName:"答题记录",
      }
      const response = await Download_answers_exal(data)
      this.spinning = false
    },
    returnBtn(){
      window.history.back()
    },
    paginationBtn(){

    }
  }
}
</script>

<style lang="scss" scoped>
.titleTop{
  height: 50px;
  border-bottom: 10px solid #f5f5f5;
  line-height: 40px;
  font-weight: bold;
  color: black;
  padding-left: 15px;
}
.liveMeet-top{
  width: 100%;
  height: 85px;
  border-bottom: 15px solid #f0f2f5;
  display: flex;
  justify-content: space-between;
  .liveMeet-top-input{
    display: flex;
    justify-content: center;
    width: 25%;
    height: 50px;
    line-height: 30px;
    padding-top: 20px;
    .input{
      width: 70%;
      margin-left: 10px;
    }
  }
}
.activity-table{
  width: 100%;
  min-height: 600px;
}
</style>
